import React from "react";

import Body from "./Body/body";

import './App.css'



function Home() {
  
    return (
        <div>
           
            <Body />
          
          
        </div>
    );
}

export default Home;