import React, { useState } from 'react';
import '../App.css';

export default function Header({ scrollToHome, scrollToAbout, scrollToServices, scrollToPortfolio, scrollToContact, scrollToJob }) {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="bg-dark-900 py-4 px-6 shadow-md bg-custom-bg text-white fixed top-0 left-0 right-0 z-50">
            <div className="flex justify-between items-center">
                <div className="flex items-center">
                    <img src='/images/Logo.png' alt="Trade Vest Logo" className="mr-2 h-12 w-16" />
                    <a href="/" className="text-3xl font-bold text-white hover:text-blue-600 focus:outline-none">Trade Vest</a>
                </div>
                <div className="hidden sm:block">
                    <nav className="flex space-x-6">
                        <button onClick={scrollToHome} className="text-white hover:text-blue-600 focus:outline-none">Home</button>
                        <button onClick={scrollToAbout} className="text-white hover:text-blue-600 focus:outline-none">About Us</button>
                        <button onClick={scrollToServices} className="text-white hover:text-blue-600 focus:outline-none">Services</button>
                        <button onClick={scrollToPortfolio} className="text-white hover:text-blue-600 focus:outline-none">Portfolio</button>
                        <button onClick={scrollToContact} className="text-white hover:text-blue-600 focus:outline-none">Contact Us</button>
                        <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md focus:outline-none"
                            onClick={scrollToJob}
                        >
                            Jobs
                        </button>
                    </nav>
                </div>
                <div className="sm:hidden">
                    <button onClick={toggleMenu} className="text-3xl text-white focus:outline-none">
                        &#9776;
                    </button>
                </div>
            </div>
            <nav className={`mt-4 ${menuOpen ? 'flex' : 'hidden'} flex-col sm:hidden`}>
                <ul className="flex flex-col space-y-4 items-center">
                    <li><button onClick={scrollToHome} className="text-white hover:text-blue-600 focus:outline-none">Home</button></li>
                    <li><button onClick={scrollToAbout} className="text-white hover:text-blue-600 focus:outline-none">About Us</button></li>
                    <li><button onClick={scrollToServices} className="text-white hover:text-blue-600 focus:outline-none">Services</button></li>
                    <li><button onClick={scrollToPortfolio} className="text-white hover:text-blue-600 focus:outline-none">Portfolio</button></li>
                    <li><button onClick={scrollToContact} className="text-white hover:text-blue-600 focus:outline-none">Contact Us</button></li>
                    <li>
                        <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md focus:outline-none"
                            onClick={scrollToJob}
                        >
                            Jobs
                        </button>
                    </li>
                </ul>
            </nav>
        </header>
    );
}
