import React from "react";
import '../App.css'

export default function Testimonials() {
    return (
        <div className="container mx-auto p-8">
            <h1 className="text-4xl font-bold text-center mb-12">Team</h1>
            <div className="flex flex-wrap items-stretch justify-around gap-8">
                <div className="md:flex md:items-center md:justify-start md:flex-col md:gap-4 mt-8 max-w-xs">
                    <div className="md:flex-shrink-0">
                        <img src='/images/male.jpeg' alt='Randall Brown' className="w-40 h-40 object-cover rounded-full mx-auto" />
                        <div className="mt-4 text-center">
                            <div className="uppercase tracking-wide text-sm text-white-800 font-semibold">Randall Brown</div>
                            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold mt-1">Founder and C.E.O</div>
                            <p className="mt-2 text-gray-500 text-sm">As Founder and CEO, Randall drives Trade-vest’s growth and expansion efforts and spends his days listening to and collaborating with clients. Randall started the credit group of Trinity Brokers and was an MD at Citi. Randall is passionate about his family and making home videos with his kids. He has a B.S. with honors from Cornell University and played on Cornell’s football team.</p>
                        </div>
                    </div>
                </div>

                <div className="md:flex md:items-center md:justify-start md:flex-col md:gap-4 mt-8 max-w-xs">
                    <div className="md:flex-shrink-0">
                        <img src='/images/female.jpeg' alt='Lauren Burd' className="w-40 h-40 object-cover rounded-full mx-auto" />
                        <div className="mt-4 text-center">
                            <div className="uppercase tracking-wide text-sm text-white-800 font-semibold">Lauren Burd</div>
                            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold mt-1">Head of Product Sales</div>
                            <p className="mt-2 text-gray-500 text-sm">As the Head of Product Sales, Lauren and her team are responsible for growing client adoption and use of the Trade-vest platform. Lauren is passionate about the client experience; actively listening to clients and working with them to improve the product and trading experience. She graduated with a B.A. from St. Lawrence University, where she was on the women’s squash team.</p>
                        </div>
                    </div>
                </div>

                <div className="md:flex md:items-center md:justify-start md:flex-col md:gap-4 mt-8 max-w-xs">
                    <div className="md:flex-shrink-0">
                        <img src='/images/female1.jpeg' alt='Jason Quinn' className="w-40 h-40 object-cover rounded-full mx-auto" />
                        <div className="mt-4 text-center">
                            <div className="uppercase tracking-wide text-sm text-white-800 font-semibold">Jason Quinn</div>
                            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold mt-1">Head of People</div>
                            <p className="mt-2 text-gray-500 text-sm">As Head of People, Jason is responsible for all aspects of the HR function. He partners with the businesses on talent acquisition, development, compensation, and employee engagement. He focuses on maintaining the Trade-vest’s culture of high-performing, collaborative, and team-oriented people. Jason has a B.A. from the University of Maryland.</p>
                        </div>
                    </div>
                </div>

                <div className="md:flex md:items-center md:justify-start md:flex-col md:gap-4 mt-8 max-w-xs">
                    <div className="md:flex-shrink-0">
                        <img src='/images/client1.jpeg' alt='Tony Sobel' className="w-40 h-40 object-cover rounded-full mx-auto" />
                        <div className="mt-4 text-center">
                            <div className="uppercase tracking-wide text-sm text-white-800 font-semibold">Tony Sobel</div>
                            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold mt-1">Chief Financial Officer & Chief Operating Officer</div>
                            <p className="mt-2 text-gray-500 text-sm">As both CFO and COO, Tony is responsible for Trade-vest’s overall financial and strategic wellbeing and also oversees Trade-vest’s day-to-day operations, finance, accounting/tax, and legal/compliance functions. Prior to Trade-vest, Tony held senior level strategy, operational, and financial roles at BlackRock and Credit Suisse. Tony has a BBA from the University of Notre Dame and in his free time enjoys travel and boating and is a keen golfer.</p>
                        </div>
                    </div>
                </div>

                <div className="md:flex md:items-center md:justify-start md:flex-col md:gap-4 mt-8 max-w-xs">
                    <div className="md:flex-shrink-0">
                        <img src='/images/worker.jpeg' alt='Joe Hawes' className="w-40 h-40 object-cover rounded-full mx-auto" />
                        <div className="mt-4 text-center">
                            <div className="uppercase tracking-wide text-sm text-white-800 font-semibold">Joe Hawes</div>
                            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold mt-1">Chief Technology Officer</div>
                            <p className="mt-2 text-gray-500 text-sm">As Chief Technology Officer, Joe and his team bring ideas to life through software and hardware. With his significant experience leading, growing, and scaling technology organizations, Joe experiments with new ways of thinking and builds innovative tech solutions for Trade-vest. Joe has a B.S. from the University of Scranton.</p>
                        </div>
                    </div>
                </div>

                <div className="md:flex md:items-center md:justify-start md:flex-col md:gap-4 mt-8 max-w-xs">
                    <div className="md:flex-shrink-0">
                        <img src='/images/team.jpeg' alt='Justine Dreisiger' className="w-40 h-40 object-cover rounded-full mx-auto" />
                        <div className="mt-4 text-center">
                            <div className="uppercase tracking-wide text-sm text-white-800 font-semibold">Justine Dreisiger</div>
                            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold mt-1">Chief Product Officer & Global Head of Sales</div>
                            <p className="mt-2 text-gray-500 text-sm">As Chief Product Officer and Global Head of Sales, Justine and her team are responsible for bringing the business vision into reality. Using her deep experience in the credit markets and expertise in market structure, Justine focuses on delivering well-designed, integrated, and innovative solutions to Trade-vest’s clients. Before joining Trade-vest, she was a PM at Millenium and Caxton and an MD and trader at Lehman Brothers and Barclays. She graduated with a B.S. in Applied Economics from Cornell University.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}
