import React, { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header/header';
import Home from './Home';
import About from './About/about';
import Services from './Services/services';
import Testimonials from './Testimonial/testimonial';
import Footer from './Footer/footer';
import Project from './Projects/project'
import EmploymentForm from './Form/form';
import Statistics from './Stats/statistics';

const App = () => {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const portfolioRef = useRef(null);
  const contactRef = useRef(null);
  const jobRef = useRef(null);

  const scrollToHome = () => {
    if (homeRef.current) {
      homeRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToAbout = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToServices = () => {
    if (servicesRef.current) {
      servicesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToPortfolio = () => {
    if (portfolioRef.current) {
      portfolioRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToJob = () => {
    if (jobRef.current) {
      jobRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <div className='bg-custom-bg text-white'>

      <Router>
        <Header
          scrollToHome={scrollToHome}
          scrollToAbout={scrollToAbout}
          scrollToServices={scrollToServices}
          scrollToPortfolio={scrollToPortfolio}
          scrollToContact={scrollToContact}
          scrollToJob={scrollToJob}
        />
        <Routes>
          <Route path="/" element={
            <>
              <div ref={homeRef}><Home /></div>
              <div ref={aboutRef}><About /></div>
              <div ref={servicesRef}><Services /></div>
              <div ref={portfolioRef}><Testimonials /></div>
              <div ref={jobRef}> <Project /></div>
              <Statistics />
              <div ref={contactRef}><Footer /></div>
            </>
          } />

          <Route path="/apply" element={<EmploymentForm />} />

      

        </Routes>


      </Router>


    </div>
  );
};

export default App;







