import React from "react";
import '../App.css';

export default function Services() {
    return (
        <div className="container mx-auto p-8">
            <h1 className="text-4xl font-bold text-center mb-12">What We Offer</h1>

            <div className="shadow-lg rounded-lg p-6 mb-8">
                <div>
                    <div className="uppercase tracking-wide text-2xl md:text-3xl text-indigo-500 text-center font-semibold">The Trade-vest Ecosystem</div>
                    <p className="mt-4 mb-6 text-gray-500">Trade-vest delivers a full ecosystem of protocols and trading solutions within one easy-to-use platform: a seamless and efficient way to trade all protocols and access liquidity from a growing and diverse network of buy-side and sell-side institutions. Built using leading-edge technology and product design, Trade-vest optimizes the credit trading experience by integrating into your specific workflows.</p>
                </div>
                <img src='/images/services1.jpeg' alt='services1' className="w-full object-cover rounded-t-lg" />
            </div>

            <div class="shadow-lg rounded-lg p-6 mb-12">
                <div class="uppercase tracking-wide text-2xl md:text-3xl  text-indigo-500 text-center font-semibold">
                    An Optimized Trading Experience, with Protocol Flexibility, Integrated Real-Time Data, and Analytics Tools
                </div>
            </div>


            <div className="shadow-lg rounded-lg p-6 flex flex-col md:flex-row mb-8">
                <div className="md:w-1/2 md:pr-4">
                    <img src="/images/service2.gif" alt="services2" className="w-full object-cover rounded-t-lg md:rounded-lg" />
                </div>
                <div className="mt-4 md:mt-0 md:w-1/2 md:pl-4">
                    <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Personalized Experience</div>
                    <p className="mt-2 text-gray-500">Creating visually compelling designs for print and digital media.</p>
                </div>
            </div>

            <div className="shadow-lg rounded-lg p-6 flex flex-col md:flex-row mb-8">
                <div className="md:w-1/2 md:pr-4">
                    <img src="/images/service3.jpeg" alt="services3" className="w-full object-cover rounded-t-lg md:rounded-lg" />
                </div>
                <div className="mt-4 md:mt-0 md:w-1/2 md:pl-4">
                    <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Data and Analytics</div>
                    <p className="mt-2 text-gray-500">Inform and optimize trading decisions with access to real-time market data and analytics for price discovery, intelligent execution, and TCA.</p>
                </div>
            </div>

            <div className="shadow-lg rounded-lg p-6 flex flex-col md:flex-row mb-8">
                <div className="md:w-1/2 md:pr-4">
                    <img src='/images/proctol.gif' alt="services4" className="w-full object-cover rounded-t-lg md:rounded-lg" />
                </div>
                <div className="mt-4 md:mt-0 md:w-1/2 md:pl-4">
                    <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Protocol Flexibility</div>
                    <p className="mt-2 text-gray-500">Trade the way you want – choose the protocol that best suits your goals. All Trade-vest protocols enable best execution and include robust automated spotting functionality.</p>
                </div>
            </div>

            <div className="shadow-lg rounded-lg p-6 flex flex-col md:flex-row mb-8">
                <div className="md:w-1/2 md:pr-4">
                    <img src='/images/liquidity.gif' alt="services5" className="w-full object-cover rounded-t-lg md:rounded-lg" />
                </div>
                <div className="mt-4 md:mt-0 md:w-1/2 md:pl-4">
                    <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Liquidity and Technology</div>
                    <p className="mt-2 text-gray-500">Technology is a powerful tool for unlocking liquidity. Interact with high-touch and algorithmic liquidity providers to gain access to efficient, aggregated liquidity that you can’t find elsewhere.</p>
                </div>
            </div>
        </div>
    );
}
