import React from 'react';
import '../App.css';
import SvgComponent from './background';

export default function Body() {
    return (
        <div className="flex justify-center items-center lg:min-h-screen m-4 bg-gray-900" id="sectionB">
            <div className="relative w-full max-w-4xl ">
            <div className="w-full h-full">
                    <SvgComponent />
                </div>
                <div className="hidden sm:block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center px-4">
                    <h1 className="text-white text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2">Welcome to the Trade Vest</h1>
                    <p className="text-white text-sm sm:text-lg md:text-xl lg:text-2xl mb-4">Where creativity meets innovation.</p>
                  
                </div>
            </div>
        </div>
    );
}
