import React, { useState } from 'react';
import axios from 'axios';
import Success from './success';
import '../App.css';

const EmploymentForm = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    other_name: '',
    street: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    phone_number: '',
    email: '',
    work_eligibility: false,
    criminal_convictions: '',
    currently_employed: false,
  });

  const [submissionStatus, setSubmissionStatus] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(''); // Error state

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue
    }));
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();   
    setLoading(true); // Start loading
    setError(''); // Clear any previous errors
    try {
      // Send form data as JSON
      const response = await axios.post(
        'https://vintage-backend-creatify.vercel.app/submit-form',
        {
          ...formData, 
        }
      );
      console.log('Response from backend:', response.data);
      setSubmissionStatus('success');
    } catch (error) {
      console.error('Error submitting form data:', error);
      setError('Failed to submit the form. Please try again later.');
    } finally {
      setLoading(false); // End loading
    }
  };

  if (submissionStatus === 'success') {
    return <Success />;
  }

  return (
    <div className="mt-20 mx-2">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {error && <p className="text-red-500">{error}</p>} {/* Error state */}
      <form onSubmit={handleSubmit} className="max-w-lg w-full mx-auto p-4 bg-white shadow-md rounded-md">
        <h1 className="text-3xl text-black p-4 font-bold text-center">Apply to Creatify Agency</h1>
        <p className="text-center text-black mb-6">This is a general application form. Regardless of the role you are applying for, you would be contacted if eligibile.</p>
        <h1 className="pb-4 text-black font-bold text-center">PERSONAL INFORMATION</h1>

        <div className="mb-4">
          <label htmlFor="first_name" className="form-label">
            First Name:
          </label>
          <input
            type="text"
            id="first_name"
            name="first_name"
            value={formData.first_name}
            onChange={handleInputChange}
            className="form-input"
            placeholder="First name"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="last_name" className="form-label">
            Last Name:
          </label>
          <input
            type="text"
            id="last_name"
            name="last_name"
            value={formData.last_name}
            onChange={handleInputChange}
            className="form-input"
            placeholder="Last name"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="other_name" className="form-label">
            Other Names:
          </label>
          <input
            type="text"
            id="other_name"
            name="other_name"
            value={formData.other_name}
            onChange={handleInputChange}
            className="form-input"
            placeholder="Other name"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="form-label">
            Email Address:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="form-input"
            placeholder="email@address.com"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="address" className="form-label">
            Address:
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            className="form-input"
            placeholder="Address"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="city" className="form-label">
            City:
          </label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
            className="form-input"
            placeholder="City"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="state" className="form-label">
            State:
          </label>
          <input
            type="text"
            id="state"
            name="state"
            value={formData.state}
            onChange={handleInputChange}
            className="form-input"
            placeholder="state"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="zip_code" className="form-label">
            Zip Code:
          </label>
          <input
            type="text"
            id="zip_code"
            name="zip_code"
            value={formData.zip_code}
            onChange={handleInputChange}
            className="form-input"
            placeholder="zip code"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="phone_number" className="form-label">
            Phone:
          </label>
          <input
            type="tel"
            id="phone_number"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleInputChange}
            className="form-input"
            placeholder="Phone"
            required
          />
        </div>

        <h1 className="pb-4 text-black font-bold text-center">Work Eligibility and Criminal Records</h1>

        <div className="mb-4">
          <label htmlFor="work_eligibility" className="form-label">
            Work Eligibility:
          </label>
          <select
            id="work_eligibility"
            name="work_eligibility"
            value={formData.work_eligibility}
            onChange={handleInputChange}
            className="form-input"
            required
          >
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="criminal_convictions" className="form-label">
            Criminal Convictions:
          </label>
          <textarea
            id="criminal_convictions"
            name="criminal_convictions"
            value={formData.criminal_convictions}
            onChange={handleInputChange}
            className="form-input"
            placeholder="Describe any criminal convictions"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="currently_employed" className="form-label">
            Currently Employed:
          </label>
          <select
            id="currently_employed"
            name="currently_employed"
            value={formData.currently_employed}
            onChange={handleInputChange}
            className="form-input"
            required
          >
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
        </div>

        <h1 className="pb-4 text-black font-bold text-center">Profile</h1>
        <div className="mb-4">
          <label htmlFor="resume" className="form-label">
            Résumé / CV
          </label>
          <p className="text-gray-500 mb-2">Accepted file formats: .pdf, .docx</p>
          <input
            type="file"
            id="resume"
            name="resume"
            onChange={handleFileChange}
            className="hidden"
            accept=".pdf,.docx"
          />
          <div
            className="block w-full py-2 px-4 border border-gray-300 rounded-md text-center cursor-pointer text-gray-500 hover:bg-gray-100"
            onClick={() => document.getElementById('resume').click()}
          >
            <svg
              className="inline-block mr-2 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
            </svg>
            Attach Résumé / CV
          </div>
          {selectedFile && (
            <div className="mt-4 flex items-center">
              <span className="mr-2 text-black">{selectedFile.name}</span>
              <button
                type="button"
                className="text-red-500 hover:text-red-700"
                onClick={handleRemoveFile}
              >
                <svg
                  className="inline-block w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>

        <button
          type="submit"
          className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          disabled={loading} // Disable button while loading
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default EmploymentForm;
