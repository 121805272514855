import React from "react";
import SuccLogo from "./succlogo";
function Success() {

  return (
    <div className="flex flex-col items-center justify-center h-screen sm: m-2">
      <div className="max-w-lg w-200 mx-auto p-6 bg-white shadow-md rounded-md text-center">
        <SuccLogo className="w-12 h-12 mx-auto text-indigo-600" />
        <h1 className="text-3xl p-4 font-bold text-black">Thank you, your application has been received successfully.</h1>
        <p className="text-gray-600 mb-4">We will be in touch with you at the email address provided. Please check your inbox for further instructions.</p>
      </div>
    </div>
  )
}

export default Success