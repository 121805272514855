import React from 'react';
import '../App.css';

export default function Statistics() {
    return (
        <div className=" py-16">
            <div className="container mx-auto px-4 text-center">
                <h2 className="text-3xl font-bold mb-4">Power in Numbers</h2>
                <p className="mb-8">
                    We believe that an engaged community of users, connected through technology,
                    optimizes liquidity for all participants.
                </p>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
                    <div className="bg-gray-100 text-black  p-6 rounded-lg shadow-md">
                        <h3 className="text-4xl font-bold mb-2">860+</h3>
                        <p>Onboarded institutions</p>
                    </div>
                    <div className="bg-gray-100 text-black  p-6 rounded-lg shadow-md">
                        <h3 className="text-4xl font-bold mb-2">110K+</h3>
                        <p>Unique trading connections created</p>
                    </div>
                    <div className="bg-gray-100 p-6 text-black rounded-lg shadow-md">
                        <h3 className="text-4xl font-bold mb-2">57%</h3>
                        <p>Trading volume growth YoY</p>
                    </div>
                </div>
                <p className="mt-8">As of May 2024</p>
            </div>
        </div>
    );
}
