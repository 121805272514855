import React from "react";
import '../App.css';
import { FaTwitter, FaFacebookF, FaInstagram } from 'react-icons/fa';

export default function Footer() {
    return (
        <div>
            <footer className="bg-gray-800 text-gray-300 py-12">
                <div className="container mx-auto px-4">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div>
                            <h2 className="text-lg font-bold mb-4">Contact Us</h2>
                            <p>159 W. 25th Street,</p>
                            <p>4th Floor, New York, NY 10001</p>
                            <p>Email: info@trade-vest.com</p>
                            <p>Phone: (315) 260-8452</p>
                        </div>
                        <div>
                            <h2 className="text-lg font-bold mb-4">Follow Us</h2>
                            <ul className="flex space-x-4">
                                <li>
                                    <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer" className="hover:text-white">
                                        <FaTwitter size={24} />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer" className="hover:text-white">
                                        <FaFacebookF size={24} />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer" className="hover:text-white">
                                        <FaInstagram size={24} />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="mt-8 text-center">
                        &copy; 2024 Trade Vest | All Rights Reserved
                    </div>
                </div>
            </footer>
        </div>
    );
}
