import React from 'react';
import '../App.css';

export default function About() {
    return (
        <div className='p-8' id="sectionA">
            <h1 className="text-4xl font-bold text-center mb-4">About Us</h1>
            <p>Trade-vest is building tomorrow’s credit trading network. We’re a rapidly growing fintech bringing leading-edge technology and product design to corporate bond trading. With a start-up mentality, we’re constantly innovating and advancing, remaining nimble and agile as we grow. We combine market expertise with a diversity of thinking - experiences, backgrounds, 
                and opinions from a variety of industries collaborating 
                to drive innovation and bring ideas to life.
                 Our business model thrives on participation and connection, and so does our culture.
                  We find joy in solving problems and working together towards common goals. 
                  Passionate, curious, and ambitious with a sense of fun.</p>
        </div>
    );
}
