import React from 'react';
import { useNavigate } from 'react-router-dom';
import ProjectImage from './svgcomp';
import '../App.css';

export default function Project() {
    const navigate = useNavigate();

    const handleApplyClick = () => {
        navigate('/apply');
    };

    return (
        <div className="container mx-auto p-8">
            <h1 className="text-4xl font-bold text-center mb-12">Current Opportunities</h1>
            <div className="flex flex-wrap items-stretch justify-around gap-8">
                <div className="md:flex md:flex-col md:items-center md:gap-4 mt-8 max-w-xs">
                    <div className="md:flex-shrink-0">
                        <ProjectImage />
                        <div className="mt-4 text-center">
                            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold mt-2">Payroll Clerk</div>
                            <p className="mt-2 text-white-800">PAY: $35 per hour.</p>
                            <p className="mt-2 text-gray-500 text-sm">Pays employees by calculating pay and deductions and issuing checks. Maintains payroll information by collecting, calculating, and entering data into the payroll system, as well as retrieving data when necessary.</p>
                            <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleApplyClick}>Apply Here</button>
                        </div>
                    </div>
                </div>

                <div className="md:flex md:flex-col md:items-center md:gap-4 mt-8 max-w-xs">
                    <div className="md:flex-shrink-0">
                        <img src="/images/data.jpeg" alt="rounded-full" class="rounded-lg" />
                        <div className="mt-4 text-center">
                            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold mt-2">Data Entry</div>
                            <p className="mt-2 text-white-800">PAY: $30 per hour.</p>
                            <p className="mt-2 text-gray-500 text-sm">You will be responsible for maintaining the customer database by entering new and updated information and preparing source files that they will use in computerized formats. The process includes compiling all records into one file or table and sorting it based on criteria.</p>
                            <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleApplyClick}>Apply Here</button>
                        </div>
                    </div>
                </div>

                <div className="md:flex md:flex-col md:items-center md:gap-4 mt-8 max-w-xs">
                    <div className="md:flex-shrink-0">
                        <img src="/images/download.jpeg" alt="Insurance Agent" className="rounded-lg" />
                        <div className="mt-4 text-center">
                            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold mt-2">Insurance Licensed Agent</div>
                            <p className="mt-2 text-white-800">PAY: $40 per hour.</p>
                            <p className="mt-2 text-gray-500 text-sm">As an Insurance Licensed Agent, you will be responsible for helping clients choose the right insurance policies to protect their assets. You'll provide advice on coverage options, handle policy renewals, and manage claims.</p>
                            <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleApplyClick}>Apply Here</button>
                        </div>
                    </div>
                </div>


                <div className="md:flex md:flex-col md:items-center md:gap-4 mt-8 max-w-xs">
                    <div className="md:flex-shrink-0">
                        <img src="/images/personal.jpeg" alt="rounded-full" class="rounded-lg" />
                        <div className="mt-4 text-center">
                            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold mt-2">Personal Assistant</div>
                            <p className="mt-2 text-white-800">PAY: $30 per hour.</p>
                            <p className="mt-2 text-gray-500 text-sm">You will be responsible for providing administrative support to executives and managing schedules, communications, and tasks.</p>
                            <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleApplyClick}>Apply Here</button>
                        </div>
                    </div>
                </div>

                <div className="md:flex md:flex-col md:items-center md:gap-4 mt-8 max-w-xs">
                    <div className="md:flex-shrink-0">
                        <img src="/images/customer.jpeg" alt="rounded-full" class="rounded-lg" />
                        <div className="mt-4 text-center">
                            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold mt-2">Customer Service Representative</div>
                            <p className="mt-2 text-white-800">PAY: $30 per hour.</p>
                            <p className="mt-2 text-gray-500 text-sm">You will be responsible for responding to customer inquiries via phone, email, and chat. Resolve customer complaints and issues in a timely and professional manner. Process orders, returns, and exchanges.</p>
                            <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleApplyClick}>Apply Here</button>
                        </div>
                    </div>
                </div>

                <div className="md:flex md:flex-col md:items-center md:gap-4 mt-8 max-w-xs">
                    <div className="md:flex-shrink-0">
                        <img src="/images/recruiter.jpeg" alt="rounded-full" class="rounded-lg" />
                        <div className="mt-4 text-center">
                            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold mt-2">Recruiter</div>
                            <p className="mt-2 text-white-800">PAY: $28 per hour.</p>
                            <p className="mt-2 text-gray-500 text-sm">You will be responsible for sourcing, screening, interviewing, and hiring potential candidates for various positions within our organization. You will work closely with hiring managers to understand their staffing needs and develop a recruitment strategy that aligns with our business objectives.</p>
                            <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleApplyClick}>Apply Here</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
